export const ACTIVATE_RIDES = `
  mutation($rideIds: [ID!]!){
    activateRide(rideIds: $rideIds){
      id
      name
      description
      price
      location
      startTime
      endTime
      capacity
      status
    }
  }
`

export const PAUSE_RIDES = `
  mutation($rideIds: [ID!]!){
    pauseRide(rideIds: $rideIds){
      id
      name
      description
      price
      location
      startTime
      endTime
      capacity
      status
    }
  }
`

export const UPDATE_RIDES = `
  mutation($update: updateRideInput!){
    updateRide(update: $update){
      id
      name
      description
      price
      location
      startTime
      endTime
      capacity
      status
    }
  }
`

export const DELETE_RIDES = `
  mutation($rideIds: [ID!]!){
    deleteRides(rideIds: $rideIds){
      id
      name
      description
      price
      location
      startTime
      endTime
      capacity
      status
    }
  }
`


export const ADD_RIDES = `
  mutation($input: createRideInput!){
    createRide(input: $input){
      id
      name
      description
      price
      location
      startTime
      endTime
      capacity
      status
      rideType
    }
  }
`

export const CANCEL_BOOKING = `
  mutation($bookingIds: [ID!]!){
    cancelBooking(bookingIds: $bookingIds){
      id
      status
    }
  }
`

export const CANCEL_BULK_BOOKING = `
  mutation($bookingIds: [ID!]!){
    cancelBulkBooking(bookingIds: $bookingIds){
      id
      status
    }
  }
`