import React from 'react';
import Skeleton from './App/layout/Skeleton';
import { Route } from 'react-router-dom'

class ErrorBoundary extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error,errorInfo)  
  }

  render() {
    if(this.state.hasError){
      return (
        <Skeleton id="ErrorPage" theme="light" fullFooter>
          <div className="error-404">
        <div className="error-code mb-5 m-t-20">Oops! <i className="fa fa-warning"></i></div>
        <h3 className="font-bold text-center mb-5">Oops! There is something wrong with the app. This is embarassing. We will be on the issue and will fix it soon.</h3>

        <div className="error-desc">
            Sorry, but the application is currently under maintainence or repair/ <br/>
            Try refreshing the page or wait for some time before coming back.
            <div><br/>
            </div>
        </div>
        </div>
        </Skeleton>
      );
    } else {
      return this.props.children
    }
  }
};

const RouteWithErrorBoundary = (props) => {
  return (
    <ErrorBoundary key={`${props.path}-${props.exact ? true : false}`}>
      <Route {...props} />
     </ErrorBoundary>
  )
}

export default RouteWithErrorBoundary
