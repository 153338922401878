import React, { useState, useEffect } from 'react';
import logo from '../../../ferryassets/img/ferry_app_icon.png'
import poom from '../../../ferryassets/img/poom.png'
import { useCookies } from 'react-cookie';
import { makeRequest } from '../../../client';
import Doc from './DocService';
import PdfContainer from './PdfContainer';
import { GET_RIDES } from '../../../graphql/queries'
import { withRouter } from 'react-router'
import { withClient } from '../../../client'
import {connect} from 'react-redux'

import $ from 'jquery';

const GenerateBulkTicket = ({makeRequest, location: { state }, ...props}) => {

	const YMDHMS = (datetime) => {
	  const pad2 = (n) => n < 10 ? '0' + n : n 
	  var date = new Date(datetime);
	  return `${pad2(date.getDate())}/${pad2(date.getMonth()+1)}/${date.getFullYear()} ${pad2(date.getHours())}:${pad2(date.getMinutes())}:${pad2(date.getSeconds())}`
	}

	const [bookings, setBookings] = useState([]);
	const [featuredRides, setFeaturedRides] = useState([]);
	const [showRiders, setShowRiders] = useState(null);
	const [cookies, removeCookie] = useCookies();

	const [bookingId, setBookingId] = useState(state.id);
	const [rideId, setRideId] = useState(state.rideId);
	const [rideName, setRideName ] = useState(state.rideName)
	const [bookerName, setBookerName ] = useState(state.bookerName)
	const [schoolName, setSchoolName ] = useState(state.schoolName)
	const [schoolDistrict, setSchoolDistrict ] = useState(state.schoolDistrict)
	const [riderCount, setRiderCount ] = useState(state.riderCount)
	const [aadhar, setAadhar ] = useState(state.aadhar)
	const [status, setStatus ] = useState(state.status)

	const [transactionId, setTransactionId] = useState(state.transactionId);
	const [timestamp, setTimestamp] = useState(state.timestamp);
	const [startTime, setStartTime] = useState();
	const [endTime, setEndTime] = useState("");
	const [date, setDate] = useState("");
	const [location, setLocation] = useState("");
	const [totalPrice, setTotalPrice] = useState(0);
	const [netCharges, setNetCharges] = useState(0);
	const [rideType,setRideType] = useState("")

	const ref = React.createRef();

	const options = {
		orientation: 'landscape'
	};


	useEffect(()=>{
		makeRequest(GET_RIDES).then((res) => {
			for(var i=0;i<res.getRides.length;i++){
				if(res.getRides[i].id===rideId){
					setRideName(res.getRides[i].name);
					setStartTime(YMDHMS(res.getRides[i].startTime).substring(11, 16));
					setEndTime(YMDHMS(res.getRides[i].endTime).substring(11, 16));
					setDate(YMDHMS(res.getRides[i].startTime).substring(0, 10));
					setLocation(res.getRides[i].location);
					setRideType(res.getRides[i].rideType);
					setTotalPrice((parseInt(res.getRides[i].price)*state.riderCount)+(parseInt(res.getRides[i].price)*state.riderCount*0.02));
					setNetCharges((parseInt(res.getRides[i].price)*state.riderCount*0.02))
				}
			}
		})
	}, [])

	const createPdf = (html) => Doc.createPdf(html);
	
	return (
		<div className="container bg-light mt-5 p-3 px-4 rounded-lg">
			<h1 className="mb-4"> Your Ticket </h1>
			<PdfContainer createPdf={createPdf}>
			<div className="card-group">
				<div className="card">
					<div className="card-body" ref={ref}>
						<div className="d-flex">
							<div>
								<h3 className="card-title"><b>E-Ticket</b></h3>
								<h5>Booking ID: <b>{bookingId}</b></h5>
								<h5>Booked on: <b>{YMDHMS(timestamp)}</b></h5>
								
							</div>
							<div className="ml-auto">
								<img src={logo} alt="logo" style={{height: 100, width: 100}}></img>
							</div>
						</div>
						<br />
						<br />
						<div className="d-flex">
							<div>
								<h5 className="card-title"> Ride Name: <b>{rideName}</b></h5>
								<h5> Location: <b>{location}</b></h5>
							</div>
							<div className="ml-auto">
								<h5>Ride Type: <b>{rideType}</b></h5>
								<h5>Ride Status: <b>{status}</b></h5>
							</div>
						</div>
						<hr style={{color: '#007bff', backgroundColor: '#007bff', height: .5, borderColor : '#007bff'}}></hr>
						<div className="d-flex">
							<div>
								<h5 className="card-title">Start Time: <b>{startTime}</b></h5>
								<h4 className="card-title"><b>{date}</b></h4>
							</div>
							<div className="ml-auto">
								<img src={poom} alt="logo" style={{height: 40}}></img>
							</div>
							<div className="ml-auto">
								<h5>End Time: <b>{endTime}</b></h5>
								<h4 className="card-title"><b>{date}</b></h4>
							</div>
						</div>
						<hr style={{color: '#6c757d', backgroundColor: '#6c757d', height: .3, borderColor : '#6c757d'}}></hr>
						<div className="d-flex justify-content-center align-items-center">
							<h2> Booking Information </h2>
						</div>
						<div className="">
							<div className="ml-auto">
								<h5> Booker Name: <b>{bookerName}</b></h5>
							</div>
							<div className="ml-auto">
								<h5>School Name : <b>{schoolName}</b></h5>
							</div>
							<div className="ml-auto">
								<h5>School District : <b>{schoolDistrict}</b></h5>
							</div>
							<div className="ml-auto">
								<h5> Booker Aadhar: <b>{aadhar}</b></h5>
							</div>
							<div className="ml-auto">
								<h5> Rider Count: <b>{riderCount}</b></h5>
							</div>
						</div>

						<br />
						<br />
						<div className="d-flex">
							<div>
								<h5 className="card-title" style={{color: "#6c757d"}}> Fare and Payment Details</h5>
							</div>
							<div className="ml-auto">
							</div>
						</div>
						<hr style={{color: '#6c757d', backgroundColor: '#6c757d', height: .3, borderColor : '#6c757d'}}></hr>
						<div className="d-flex">
							<div>
								<h6 className="card-title">Base Amount: <b>Rs. {totalPrice-netCharges}</b></h6>
								<h6 className="card-title">Net Charges: <b>Rs. {netCharges}</b></h6>
								<h6 className="card-title">Total Amount: <b>Rs. {totalPrice}</b></h6>
								<h6 className="card-title">Transaction ID: <b>{transactionId}</b></h6>
							</div>
							<div className="ml-auto">
							</div>
						</div>
						<br />

						<div className="d-flex">
							<div>
								<h5 className="card-title" style={{color: "#6c757d"}}> Cancellation policy</h5>
							</div>
							<div className="ml-auto">
							</div>
						</div>

						<hr style={{color: '#6c757d', backgroundColor: '#6c757d', height: .3, borderColor : '#6c757d'}}></hr>
						<div className="d-flex">
							<div>
								<ul>
									<li>If cancelled the ticket, before 24 hrs of boarding time, 100% refund will be allowed, except admin and net charges.</li>
									<li>If cancelled before 12–24 hrs, 75% refund will be allowed, except admin and net charges.</li>
									<li>If cancelled before 4 – 8 hrs 50% refund will be allowed, except admin and net charges.</li>
									<li>If cancelled within 4 hrs No refund will be allowed.</li>
									<li>If service is cancelled due to bad weather or any other technical issues then the passenger was requested to avail the next other available slots for the same day or in the next day. If the passengers not agreed, full refund will be done in online.</li>
								</ul>
							</div>
							<div className="ml-auto">
							</div>
						</div>
						
						<br />
						<br />

						<div className="d-flex">
							<div>
								<h5 className="card-title" style={{color: "#6c757d"}}> Tourist Places</h5>
							</div>
							<div className="ml-auto">
							</div>
						</div>

						<hr style={{color: '#6c757d', backgroundColor: '#6c757d', height: .3, borderColor : '#6c757d'}}></hr>
						<div className="d-flex">
							<div>
							<p>Nearest Kanyakumari</p>	
								<ol>			
									<li>Vivekananda Rock Memorial</li>
									<li>AyyanThiruvalluvar</li>
									<li>Sunrise & Sunset view tower</li>
									<li>Devi Bagavathi Amman Temple</li>
									<li>Mahatma Gandhi Memorial</li>
									<li>kamarajar Memorial</li>
									<li>Triveni Sangamam</li>
									<li>Vattakottai Fort</li>
									<li>Thanumalayan Temple - Susindrum</li>
									<li>Nagaraja Temple - Nagarcoil</li>
									<li>Ramayana Mandapam - Vivekanandapuram</li>
									<li>Our Lady of Ransom Church - Kanyakumari</li>
								</ol>
							</div>
							<div className="ml-auto">
							</div>
						</div>
						<div className="d-flex">
							<div>
							<h4>Conditions</h4>	
								<ol>			
									<li>The ticket is Not Transferable.</li>
									<li>Please kindly note that special entry is only for upward journey and not for returm, since it is not necessary. Kindly cooperate zan addressed by crew  </li>
									<li>Passengers are requested to adhere to safety rules.- Safety First. </li>
									<li>Kindly keep the ticket/id readily available for verification.  </li>
									<li>The entry will be easy if you reach 30 minutes before the time of boarding. Please kindly avoid last minute rush.  </li>
									<li>The original Aadhar id card to be shown at the time of entry for Indians, forforeigners original passport id to shown by the visitor.  </li>
									<li>If service is cancelled due to bad weather or any other technical issues then the passenger s requested to avail the next ether available slots for the same day or in the next day. If requested, full refund will be done in online. </li>
									<li>Ferry service is subject to weather/wave/wind/tide conditions. </li>
								</ol>
							</div>
							<div className="ml-auto">
							</div>
						</div>
					</div>
				</div>
			</div>
			</PdfContainer>
		</div>
	)
}

export default withClient(withRouter(GenerateBulkTicket))