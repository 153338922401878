import React, { useState,useEffect } from 'react';
import {Row, Col, Card, Table, Form, Button, InputGroup, FormControl, Modal} from 'react-bootstrap';
import DateTimePicker from "react-datetime-picker";
import classNames from 'classnames';
import Swal from 'sweetalert2';
import { withClient } from '../../../client';
import GetExcelFile from './GetExcelFile'
import { ContactSupportOutlined } from '@material-ui/icons';
import { DELETE_RIDES, PAUSE_RIDES, ACTIVATE_RIDES, UPDATE_RIDES,ADD_RIDES } from '../../../graphql/mutation'
import { GET_RIDES, GET_BOOKING_EXCEL, GET_BULK_BOOKINGS } from '../../../graphql/queries'

const Rides = ({makeRequest}) =>  {

    const YMDHMS = (datetime) => {
      const pad2 = (n) => n < 10 ? '0' + n : n 
      var date = new Date(datetime);
      return `${pad2(date.getDate())}/${pad2(date.getMonth()+1)}/${date.getFullYear()} ${pad2(date.getHours())}:${pad2(date.getMinutes())}:${pad2(date.getSeconds())}`
    }

    const [rides, setRides] = useState([]);
    const [search, setSearch] = useState('');
    const [isLoading, setLoading] = useState('loading');
    const [show, setShow] = useState(false);
    const [showView, setShowView] = useState(false);
    const [showViewAdd, setShowViewAdd] = useState(false);
    const [showViewExport, setShowViewExport] = useState(false);
    const [ridesIdx, setRidesIdx] = useState('');

    const [ridesSelected,setRidesSelected] = useState({})
    const [rideAlert, setRideAlert] = useState(null);
    const [manageAlert, setManageAlert] = useState(null)

    const [manageRide, setManageRide] = useState({
        id: '',
        name: '',
        description: '',
        price: '',
        startTime: new Date(),
        endTime: new Date(),
        capacity: '',
        location: '',
    })

    const [addRide, setAddRide] = useState({
        name: '',
        description: '',
        price: '',
        startTime: new Date(),
        endTime: new Date(),
        capacity: '',
        location: '',
        rideType: 'General'
    })

    const [rideExport, setRideExport] = useState(null)
    const [exportStart,setExportStart] = useState(Date.now())
    const [exportEnd, setExportEnd] = useState(Date.now())
    const [exportData, setExportData] = useState([])
    const [download, setDownload] = useState(false)

    useEffect(() => {
        makeRequest(GET_RIDES).then((resp)=>{
            setRides(resp.getRides)
        });
    }, [])

    const handleSelect = (id) => {
        if(!ridesSelected[id])
            setRidesSelected({...ridesSelected,[id]: true})
        else {
            const newRidesSelected = Object.assign({},ridesSelected)
            delete newRidesSelected[id]
            setRidesSelected(newRidesSelected);
        }
    }

    const handleCloseShow = () => {
        setShowView(false);
        setManageRide({
            id: '',
            name: '',
            description: '',
            price: '',
            startTime: new Date(),
            endTime: new Date(),
            capacity: '',
            location: '',
        })
    }

    const handleCloseShowAdd = () => {
        setShowViewAdd(false);
        setAddRide({
            name: '',
            description: '',
            price: '',
            startTime: new Date(),
            endTime: new Date(),
            capacity: '',
            location: '',
            rideType: 'General'
        })
    }

    const handleCloseShowExport = () => {
        setShowViewExport(false);
        setExportEnd(Date.now())
        setExportStart(Date.now())
        setRideExport(null)
        setDownload(false)
        setExportData([])
    }

    const handleShowView = (event, ix) => {
        setShowView(true);
        const chosenRide = Object.assign({},rides[ix])
        const { rideType, ...rideToManage} = chosenRide
        rideToManage.startTime = new Date(rideToManage.startTime)
        rideToManage.endTime = new Date(rideToManage.endTime)
        setManageRide(rideToManage)
    }

    const handleShowViewExport = (event, ix) => {
        setShowViewExport(true)
        setRideExport(ix)
    }

    const activateRides = async () => {
        const rideIds = Object.keys(ridesSelected);
        if(rideIds.length === 0)
            return
        const activate = window.confirm("Are you sure ?")
        if(!activate)
            return
        try{
            const res = await makeRequest(ACTIVATE_RIDES,{rideIds: rideIds});
            setRidesSelected({})
            makeRequest(GET_RIDES).then((resp)=>{
                setRides(resp.getRides)
            });
            setRideAlert("Activated rides")
        } catch (err){
            setRideAlert("Could not activate rides")
        }
    }

    const pauseRides = async () => {
        const rideIds = Object.keys(ridesSelected);
        if(rideIds.length === 0)
            return
        const activate = window.confirm("Are you sure ?")
        if(!activate)
            return
        try{
            const res = await makeRequest(PAUSE_RIDES,{rideIds: rideIds});
            setRidesSelected({})
            makeRequest(GET_RIDES).then((resp)=>{
                setRides(resp.getRides)
            });
            setRideAlert("Paused rides")
        } catch (err){
            setRideAlert("Could not pause rides")
        }
    }

    const deleteRides = async () => {
        const rideIds = Object.keys(ridesSelected);
        if(rideIds.length === 0)
            return
        const activate = window.confirm("Are you sure ?")
        if(!activate)
            return
        try{
            const res = await makeRequest(DELETE_RIDES,{rideIds: rideIds});
            setRidesSelected({})
            makeRequest(GET_RIDES).then((resp)=>{
                setRides(resp.getRides)
            });
            setRideAlert("Deleted rides")
        } catch (err){
            setRideAlert("Could not delete rides")
        }
    }

    const manageRides = async (e) => {
        e.preventDefault()
        const rideId = manageRide.id;
        const {id,status,totalBooked, ...manageRideDetails} = manageRide;
        if(manageRideDetails.capacity < totalBooked){
          setManageAlert("Capacity cannot be less than the number of tickets booked")
          return
        }
        const vars = {
            update: {
                rideId,
                ...manageRideDetails,
                capacity: Number(manageRideDetails.capacity),
                price: Number(manageRideDetails.price),
            }
        }
        try{
            const res = await makeRequest(UPDATE_RIDES,vars);
            makeRequest(GET_RIDES).then((resp)=>{
                setRides(resp.getRides)
            });
            setRideAlert("Updated ride")
            handleCloseShow()
        } catch (err){
            setRideAlert("Could not update ride")
        }
    }

    const addRides = async (e) => {
        e.preventDefault()
        const vars = {
            input: {
                ...addRide,
                capacity: Number(addRide.capacity),
                price: Number(addRide.price),
            }
        }
        if(addRide.startTime >= addRide.endTime){
          setManageAlert("Start time should be less than end time")
          return
        }
        try{
            const res = await makeRequest(ADD_RIDES,vars);
            makeRequest(GET_RIDES).then((resp)=>{
                setRides(resp.getRides)
            });
            setRideAlert("Added ride")
            handleCloseShowAdd()
        } catch (err){
            setRideAlert("Could not update ride")
        }
    }

    const getBookingsExport = async (event) => {
      event.preventDefault()

      if(rides[rideExport].rideType === "School"){
        const vars = {
          rideId: rides[rideExport].id,
        }
        const { getBulkBookings : bookings } = await makeRequest(GET_BULK_BOOKINGS, vars)
        setExportData({ 
          name: rides[rideExport].name,
          type: rides[rideExport].rideType,
          bookings
        })
      } else {
        const vars = {
          rideId: rides[rideExport].id,
        }
        const { getBookingsExcel : bookings } = await makeRequest(GET_BOOKING_EXCEL, vars)
        setExportData({ 
          name: rides[rideExport].name,
          type: rides[rideExport].rideType,
          bookings
        })
      }
      setDownload(true)
      handleCloseShowExport()
    }
    
    const Details = () => {
        const card = rides ? rides.map((ride,ix) => {
            return (
                <tr key={ride.id}>
                    <td>
                        <input 
                          className="form-control" 
                          type="checkbox"
                          onChange={()=>{
                            handleSelect(ride.id)
                          }}
                          checked={ridesSelected[ride.id] ? true : false}
                        />
                    </td>
                    <td scope="row">
                    {' '}
                    {ix + 1}
                    </td>
                    <td>
                    {' '}
                    {ride.id}
                    </td>
                    <td>
                    {' '}
                    {ride.name}
                    </td>
                    <td>
                    {' '}
                    <p  className="desc">{ride.description}</p>
                    </td>
                    <td>
                    {' '}
                    {ride.location}
                    </td>
                    <td>
                    {' '}
                    {ride.price}
                    </td>
                    <td>
                    {' '}
                    {YMDHMS(ride.startTime)}
                    </td>
                    <td>
                    {' '}
                    {YMDHMS(ride.endTime)}
                    </td>
                    <td>
                    {' '}
                    {ride.capacity}
                    </td>
                    <td>
                    {' '}
                    {ride.rideType}
                    </td>
                    <td>
                    {' '}
                    {ride.totalBooked}
                    </td>
                    <td>
                    {' '}
                    {ride.status}{' '}
                    </td>
                    <td>
                    {' '}
                    <Button variant="success" onClick={(event) => handleShowView(event, ix)}>Manage</Button>
                    <br />
                    <Button variant="success" onClick={(event) => handleShowViewExport(event, ix)}>Export</Button>
                    </td>
                </tr>
            );
        }) : []
        return card;
    };

    const changeManageRideForm = (section,value) => {
        setManageRide(Object.assign({},{...manageRide},{[section]:value}))
    }

    const changeAddRideForm = (section,value) => {
        setAddRide(Object.assign({},{...addRide},{[section]:value}))
    }

    const manageRideForm = (
        <Form onSubmit={(e) => {manageRides(e)}}>
          <Form.Group controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Name"
              onChange={(e)=>{
                changeManageRideForm('name',e.target.value)
              }}
              value={manageRide.name}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Description"
              onChange={(e)=>{
                changeManageRideForm('description',e.target.value)
              }}
              value={manageRide.description}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicLocation">
            <Form.Label>Location</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Location"
              onChange={(e)=>{
                changeManageRideForm('location',e.target.value)
              }}
              value={manageRide.location}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicPrice">
            <Form.Label>Price</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Price"
              onChange={(e)=>{
                changeManageRideForm('price',e.target.value)
              }}
              value={manageRide.price}
              required
              pattern="\d+"
            />
          </Form.Group>
          <Form.Group controlId="formBasicCapacity">
            <Form.Label>Capacity</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Capacity"
              onChange={(e)=>{
                changeManageRideForm('capacity',e.target.value)
              }}
              value={manageRide.capacity}
              required
              pattern="\d+"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Start Time</Form.Label>
            <br/>
            <DateTimePicker
              onChange={(e)=>{
                changeManageRideForm('startTime',e)
              }}
              value={manageRide.startTime}
              disableCalendar={true}
              disableClock={true}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End Time</Form.Label>
            <br/>
            <DateTimePicker
              onChange={(e)=>{
                changeManageRideForm('endTime',e)
              }}
              value={manageRide.endTime}
              disableCalendar={true}
              disableClock={true}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
    )

    const addRideForm = (
        <Form onSubmit={(e) => {addRides(e)}}>
          <Form.Group controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Name"
              onChange={(e)=>{
                changeAddRideForm('name',e.target.value)
              }}
              value={addRide.name}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Description"
              onChange={(e)=>{
                changeAddRideForm('description',e.target.value)
              }}
              value={addRide.description}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicLocation">
            <Form.Label>Location</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Location"
              onChange={(e)=>{
                changeAddRideForm('location',e.target.value)
              }}
              value={addRide.location}
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicPrice">
            <Form.Label>Price</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Price"
              onChange={(e)=>{
                changeAddRideForm('price',e.target.value)
              }}
              value={addRide.price}
              required
              pattern="\d+"
            />
          </Form.Group>
          <Form.Group controlId="formBasicCapacity">
            <Form.Label>Capacity</Form.Label>
            <Form.Control 
              type="text" 
              placeholder="Enter Capacity"
              onChange={(e)=>{
                changeAddRideForm('capacity',e.target.value)
              }}
              value={addRide.capacity}
              required
              pattern="\d+"
            />
          </Form.Group>
          <Form.Group>
              <Form.Label>Ride Type</Form.Label>
              <Form.Control 
                as="select" 
                custom
                onChange={(e)=>{
                  console.log(e.target.value)
                  changeAddRideForm('rideType',e.target.value)
                }}
                value={addRide.rideType}
                required
              >
                <option>General</option>
                <option>Special</option>
                <option>School</option>
              </Form.Control>
            </Form.Group>
          <Form.Group>
            <Form.Label>Start Time</Form.Label>
            <br/>
            <DateTimePicker
              onChange={(e)=>{
                changeAddRideForm('startTime',e)
              }}
              value={addRide.startTime}
              disableCalendar={true}
              disableClock={true}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>End Time</Form.Label>
            <br/>
            <DateTimePicker
              onChange={(e)=>{
                changeAddRideForm('endTime',e)
              }}
              value={addRide.endTime}
              disableCalendar={true}
              disableClock={true}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
    )

    const exportForm = (
      <Form onSubmit={(e) => {getBookingsExport(e)}}>
        <h3> Export All Bookings For this ride </h3>
        <br />
        <Button variant="primary" type="submit">
          Download
        </Button>
      </Form>
    )

    return (
        <div>
            <Row>
                <Col>
                    <Card>
                        <Card.Header> 
                            <Card.Title as="h5">Rides Management</Card.Title>
                            <span className="d-block m-t-5">Easily manage rides here.</span>
                            <div className="clearfix">
                                <Button
                                  className="float-right btn btn-primary"
                                  onClick={()=>{setShowViewAdd(true)}}
                                > Add ride </Button>
                                <Button
                                  className="float-right btn btn-primary"
                                  onClick={()=>{
                                    activateRides()
                                  }}
                                > Activate Rides </Button>
                                <Button
                                  className="float-right btn btn-primary"
                                  onClick={()=>{
                                    pauseRides()
                                  }}
                                > Pause Rides </Button>
                                <Button
                                  className="float-right btn btn-primary"
                                  onClick={()=>{
                                    deleteRides()
                                  }}
                                > Delete Rides </Button>
                            </div>
                            <br />
                            <h4 className="float-right">{rideAlert}</h4>

                        </Card.Header>
                        <Card.Body  id="rides">

                            <Modal show={showView} onHide={handleCloseShow} size="lg">
                                <Modal.Header closeButton>
                                <Modal.Title>Manage Ride</Modal.Title>
                                </Modal.Header>
                                
                                <Modal.Body>
                                    <Modal.Title className="text-danger">{manageAlert}</Modal.Title>
                                    {manageRideForm}
                                </Modal.Body>

                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseShow}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showViewAdd} onHide={handleCloseShowAdd} size="lg">
                                <Modal.Header closeButton>
                                <Modal.Title>Add Ride</Modal.Title>
                                </Modal.Header>
                                
                                <Modal.Body>
                                    <Modal.Title className="text-danger">{manageAlert}</Modal.Title>
                                    {addRideForm}
                                </Modal.Body>

                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseShowAdd}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showViewExport} onHide={handleCloseShowExport} size="lg">
                                <Modal.Header closeButton>
                                <Modal.Title>Download Booking data</Modal.Title>
                                </Modal.Header>
                                
                                <Modal.Body>
                                    {exportForm}
                                    { download && (
                                        <GetExcelFile hideElement={true} name="Bookings" exportData={exportData}/>
                                    )}
                                </Modal.Body>

                                <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseShowExport}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>

                            <Table responsive hover id="table-to-xls">
                                <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Sr.No.</th>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Location</th>
                                    <th>Price</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Capacity</th>
                                    <th>Ride Type</th>
                                    <th>Total Booked</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                <Details />
                                </tbody>
                            </Table>
                            <br />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default withClient(Rides);