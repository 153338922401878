export function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      let date = new Date(currentDate);
      date.setDate(date.getDate() + 1);
      currentDate = date;
    }
    return dateArray;
  }
