import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table, Select } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { withClient } from '../../../client';
import logoFull from '../../../ferryassets/img/poom.png';
import { GET_BOOKINGS_BY_RIDE, GET_BOOKING_DATA, GET_RIDES, SEARCH_RIDES, GET_RIDES_DATE, GET_BOOKINGS, GET_BOOKING_PAGES, GET_BOOKINGS_BY_RIDES, SEARCH_BOOKINGS } from '../../../graphql/queries';
import { getDates } from '../../../utils/generalUtil';
import GetExcelFile from './GetExcelFile';
import moment from "moment"
import PdfContainer from '../Ticket/PdfContainer';
import Doc from '../Ticket/DocService';
import "./override.css"
import DateTimePicker from 'react-datetime-picker';
const Rides = ({ makeRequest, ...props }) => {

  const [download, setDownload] = useState(false)
  const [exportData, setExportData] = useState([])
  const [searchDate, setSearchDate] = useState(["", ""])
  const [state, setState] = useState({ rides: [], selectedRide: "" })
  const [state1, setState1] = useState({ rides: [], selectedRide: "" })
	const [startTimeEqualChoiceValue, setStartTimeEqualChoiceValue] = useState('')
	const [rides, setRides] = useState(null)
	const [fetching, setFetching] = useState(false)
  const [bookings, setBookings] = useState([]);
  const createPdf = (html) => Doc.createPdf(html, true);

  useEffect(() => {
    if (download) {
      setDownload(false)
    }
  }, [download])

  useEffect(()=>{
    (async () => {
      /*const { getRides } = await makeRequest(GET_RIDES, {
        byStartTimeGreaterEqual: startTimeEqualChoiceValue,
        byEndTimeLesserEqual: moment().endOf("day").toDate()
      });
      console.log('rides list => ', getRides);
      setState(state => ({ ...state, rides: getRides }))
      */
    })()
  },[])

  const Details = () => {
    const card = state.bookingsData ? state.bookingsData.map((data, i) => {
      return (
        <tr key={"date" + i}>
          <td>{i+1}</td>
          <td>{data.status}</td>
          <td>{data.name}</td>
          <td>{data.aadhar}</td>
          <td>{data.age}</td>
          <td>{data.phone}</td>
          <td>{data.gender}</td>
        </tr>
      );
    }) : []
    return card;
  };

  const Details1 = () => {
    console.log('bookings ', bookings);
    const card = bookings ? bookings.map((data, i) => {
      let x = data.riders;
      return (
        <tr>
          <td>{i+1}</td>
          <td>{data.id}</td>
          <td>{data.transactionId}</td>
          <td>{x[0].fullName}</td>
          <td>{data.tcount}</td>
          <td>{data.timestamp}</td>
        </tr>
      );
    }) : []
    return card;
  };

  	// start time filter handlers
	async function handleStartTimeEqualChoiceValue(value, value1) {
		const today = new Date(Date.now())
		if (value <= new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0)) {
			//window.alert("start time cannot be in the past")
			//return
		}
		setStartTimeEqualChoiceValue(value)
      console.log(value);
      console.log(value1);
      var endDate = value1;
      let d1 = endDate.setDate(endDate.getDate()+1);
      let d2 = endDate.setDate(endDate.getDate()-1);

      const { getRides } = await makeRequest(GET_RIDES, {
        byStartTimeGreaterEqual: value,
       // byEndTimeLesserEqual: endDate,
        byEndTimeLesserEqual: d1
      });

      console.log('with duplicate ', getRides);
if(getRides){
  var cleanGetRides = getRides.filter((getRides, index, self) =>
    index === self.findIndex((t) => (t.startTime === getRides.startTime)))
    //console.log(arr);
      console.log('Without duplicate ', cleanGetRides);
      setState(state => ({ ...state, rides: cleanGetRides }))
}
    
      setState1(state1 => ({ ...state1, rides: getRides }))
	}

  async function getBookings(){
    //console.log(state);
    //console.log(state.selectedRide);
    //console.log(state1.rides);
setBookings([]);
    let resBookingArray = [];
    let resBookingArray1 = [];
    for (let variable of state1.rides) {
      // code block to be executed
      console.log(variable.id);
      Object.prototype.id = variable.id;
      
      if(variable.startTime == state.selectedRide){
        console.log(variable.id);
        console.log('Matched');
        resBookingArray1.push(variable.id);
        var param = {
          byRideId: variable.id
        }
        await makeRequest(GET_BOOKINGS, param).then((resp1) => {
          //console.log('success fetch ');
          //console.log(resp1);
          console.log('booking data => ', resp1.getBookings);
          resp1.getBookings.map((data, i) => {
            resBookingArray.push(data);
          });
          //resBookingArray.push(resp1.getBookings);
          //setBookings(resp1.getBookings);
        }).catch(() => {
          console.log('errorrrr');
        })
      }
    }
    if(resBookingArray.length > 0){
      //console.log('resbookings array ', resBookingArray);
      //console.log('resbookings array1 ', resBookingArray1);
      //setBookings(resBookingArray);
    }
    console.log('resbookings array ', resBookingArray);
    console.log('resbookings array1 ', resBookingArray1);
    setBookings(resBookingArray);
    console.log(bookings); 
  }

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Booking Data Management</Card.Title>
              <span className="d-block m-t-5"> Export data for the date range.</span>
              <br />

            </Card.Header>
            <Form onSubmit={(e) => {
              e.preventDefault()
            }}>
              {/* <h3 className="ml-3"></h3> */}
              <div className="mt-2 clearfix">
                <div className="row">
                  <div className="col-4">
                  <DateTimePicker
						format={"dd.MM.yyyy hh:mm"}
						disableClock={true}
						type="text"
						className="form-control no-time"
						onChange={(e) => {
							handleStartTimeEqualChoiceValue(e, e)
              setFetching(true)
							//submitSearch()
						}}
						value={startTimeEqualChoiceValue}
					/>
                  </div>
                  <div className="col-4">
                    <div>
                      <Form.Control as='select' onChange={e=>{
                        e.persist()
                        console.log('e ', e);
                        if (e.target?.value)
                          setState(state => ({ ...state, selectedRide: e.target.value }))
                      }}>
                        <option>Select a ride</option>
                        {state.rides?.map(ride => (
                          <option value={ride.startTime}>{ride.name}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </div>

                </div>
              </div>
              <br />
              <Button variant="primary" type="submit"
                className="ml-2 mt-3"
                onClick={async e => {
                  console.log('hello');
                  getBookings();
                  /*if (state.selectedRide) {
                    const { getBookingsByRide } = await makeRequest(GET_BOOKINGS_BY_RIDE, { rideId: state.selectedRide })
                    console.log('getBookingsByRide ', getBookingsByRide);
                    if (getBookingsByRide.length > 0)
                      setState(state => ({ ...state, bookingsData: [
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                        ...getBookingsByRide,...getBookingsByRide,...getBookingsByRide,
                      ] }))
                  } else {
                    Swal.fire({
                      imageUrl: logoFull,
                      imageWidth: "150px",
                      title: 'Error',
                      text: 'Please select a ride to export',
                      confirmButtonText: 'OK',
                      heightAuto: false
                    });
                  }*/
                }}>
                Find
        </Button>
        {/* {exportData.length?<Button variant="primary" type="submit"
                className="ml-2 mt-3"
                style={{float:"right"}}
                onClick={e=>setDownload(true)}
              >Export</Button>:""}
              {download ? <GetExcelFile hideElement={true} exportData={exportData} name="Bookings" />:""} */}
            </Form>
            
<>
<table>
<tr>
  <td>
    Sno
  </td>
  <td>
    Id
  </td>
  <td>OrderId</td>

  <td>Name</td>
  <td>Ticket Count</td>
  <td>Timestamp</td>
</tr>
{ <Details1 />}
</table>
</>
            
            {/*
              <>
                {state.bookingsData && state.bookingsData.length ?<PdfContainer createPdf={createPdf} btnLabel={"Download Bookings"}>
                <div className="d-flex justify-content-center align-items-center">
                  <h5> {state.rides?.find(ride => ride.id === state.selectedRide)?.name || "Ride"} </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <p>Traveller's Information </p>
                </div>

                  <Table responsive hover id="table-to-xls">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>status</th>
                      <th>name</th>
                      <th>aadhar</th>
                      <th>age</th>
                      <th>phone</th>
                      <th>gender</th>
                    </tr>
                  </thead>
                  <tbody>
                    {<Details />}
                  </tbody>
                </Table> 
                </PdfContainer>: ""}
              </>
            */}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default withClient(Rides);