import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  createPdf = (html) => {
    savePDF(html, { 
      paperSize: 'A4',
      landscape: true,
      fileName: 'ticket.pdf',
      margin: 2
    })
  }
}

const Doc = new DocService();
export default Doc;