import React from "react";
import $ from "jquery";
import Auth from "./Auth";
import Booking from "./components/Booking";
import BulkBooking from "./components/BulkBooking";
import Rides from "./components/Rides";
import Export from "./components/Export";
import ExportByRide from "./components/ExportByRide";
import Home from "./components/Home";
import GenerateTicket from './components/Ticket'
import GenerateBulkTicket from './components/BulkTicket'

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const routes = [
  { path: "/login", exact: true, name: "Login", component: Auth },
  {
    path: "/logout",
    exact: true,
    name: "Logout",
    component: (props) => <Auth defaultRoutine="logout" {...props} />,
  },
];

const adminRoutes = [
  { 
    path: "/admin/dashboard", 
    exact: true, 
    name: "Dashboard", 
    component: Home 
  },
  {
    path: "/admin/booking",
    exact: true,
    name: "Bookings",
    component: Booking,
  },
  {
    path: "/admin/bulkbooking",
    exact: true,
    name: "Bulk Bookings",
    component: BulkBooking,
  },
  {
    path: "/admin/rides",
    exact: true,
    name: "Rides",
    component: Rides,
  },
  {
    path: "/admin/export",
    exact: true,
    name: "Export",
    component: Export,
  },
  {
    path: "/admin/rideexport",
    exact: true,
    name: "Rides Export",
    component: ExportByRide,
  },
  {
    path: "/ticket",
    exact: true,
    name: "ticket",
    component: GenerateTicket
  },
  {
    path: "/bulkticket",
    exact: true,
    name: "bulkticket",
    component: GenerateBulkTicket
  }
];

export { routes, adminRoutes };
