import React from 'react';
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const GetExcelFile = ({exportData, ...rest}) => {

	const YMDHMS = (datetime) => {
	  const pad2 = (n) => n < 10 ? '0' + n : n 
	  var date = new Date(datetime);
	  return `${pad2(date.getDate())}/${pad2(date.getMonth()+1)}/${date.getFullYear()} ${pad2(date.getHours())}:${pad2(date.getMinutes())}:${pad2(date.getSeconds())}`
	}

	if(exportData.type === "School"){
		return (
			<ExcelFile filename={exportData.name} {...rest}>
			    <ExcelSheet data={exportData.bookings} name="Bookings">
			        <ExcelColumn label="Booking Id" value="id" />
			        <ExcelColumn label="Transaction Id" value="transactionId" />
			        <ExcelColumn label="Number of Passengers" value="riderCount" />
			        <ExcelColumn label="Timestamp" value={(val) => YMDHMS(val.timestamp)} />
			        <ExcelColumn label="Status" value="status" />
			        <ExcelColumn label="Aadhar / Passport Number" value="aadhar" />
			        <ExcelColumn label="Booker Name" value="bookerName" />
			        <ExcelColumn label="School Name" value="schoolName" />
			        <ExcelColumn label="School District" value="schoolDistrict" />
			        <ExcelColumn label="Comments" value={(val) => ""} />
			    </ExcelSheet>
			</ExcelFile>
		)
	}

	return (
		<ExcelFile filename={exportData.name} {...rest}>
		    <ExcelSheet data={exportData.bookings} name="Bookings">
		        <ExcelColumn label="Booking Id" value="id" />
		        <ExcelColumn label="Transaction Id" value="transactionId" />
		        <ExcelColumn label="Number of Passengers" value="riders" value={(val) => `${val.riders.length}`} />
		        <ExcelColumn label="Timestamp" value={(val) => YMDHMS(val.timestamp)} />
		        <ExcelColumn label="Status" value="status" />
		        <ExcelColumn label="Name of Primary Rider" value={(val) => val.riders[0].fullName} />
		        <ExcelColumn label="Names of Riders" value={(val) => val.riders.slice(1).reduce((acc,cur) => cur.fullName ? `${acc} ${cur.fullName}` : acc, "")} />
		        <ExcelColumn label="Aadhar / Passport Number" value={(val) => val.riders[0].aadhar} />
		        <ExcelColumn label="Aadhar / Passport Number of Riders" value={(val) => val.riders.slice(1).reduce((acc,cur) => cur.aadhar ? `${acc} ${cur.aadhar}` : acc, "")} />
		        <ExcelColumn label="Comments" value={(val) => ""} />
		    </ExcelSheet>
		</ExcelFile>
	)
}

export default GetExcelFile;