import React from 'react';
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const GetExcelFile = ({ exportData, ...rest }) => {
	console.log({ exportData })
	return (
		<ExcelFile filename={"filename"} {...rest}>
			<ExcelSheet
			data={exportData}
			//  data={[{
			// 	date: "date",
			// 	passengers: "passengers",
			// 	normalTicket: "normalTicket",
			// 	specialTicket: "specialTicket",
			// 	ticketAmount: "ticketAmount",
			// 	normalAmount: "normalAmount",
			// 	specialAmount: "specialAmount",
			// 	schoolTicket: "schoolTicket",
			// 	schoolAmount: "schoolAmount",
			// 	igst: "igst",
			// 	sgst: "sgst",
			// 	tnmb: "tnmb",
			// 	ttdc: "ttdc",
			// 	amtExcTax: "amtExcTax",
			// 	psc: "psc",
			// }]}
				// dataset={[{
				name="Collections">
				<ExcelColumn label="Date" value="date" />
				<ExcelColumn label="No. Passengers" value="passengers" />
				<ExcelColumn label="Normal Ticket" value="normalTicket" />
				<ExcelColumn label="Amount" value="normalAmount" />
				<ExcelColumn label="Special Ticket" value="specialTicket" />
				<ExcelColumn label="Amount" value="specialAmount" />
				<ExcelColumn label="Student" value="schoolTicket" />
				<ExcelColumn label="Amount" value="schoolAmount" />
				<ExcelColumn label="Ticket Amount" value="ticketAmount" />
				<ExcelColumn label="Amt exc GST" value="amtExcTax" />
				<ExcelColumn label="IGST 9%" value="igst" />
				<ExcelColumn label="SGST 9%" value="sgst" />
				<ExcelColumn label="TNMB 5%" value="tnmb" />
				<ExcelColumn label="TTDC Rs5/ticket" value="ttdc" />
				<ExcelColumn label="PSC" value="psc" />
			</ExcelSheet>
		</ExcelFile>
	)
}

export default GetExcelFile;