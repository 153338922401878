import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { connect } from "react-redux";
import { useDispatch, useSelector } from 'react-redux';

// import assets
import ferryLogoTextColor from '../../ferryassets/img/poom.png';
import ferryLogoTextColorInverted from '../../ferryassets/img/poom.png';

import Login from './components/Login';
import Skeleton from '../layout/Skeleton';

import { LOGOUT_MUTATION } from '../../graphql/mutation';

const Auth = ({ defaultRoutine = 'login'}) => {
  const state = useSelector((state) => state.auth)
  const dispatch = useDispatch();
  const [routine, setRoutine] = useState(defaultRoutine);
  const [ferryLogo, setFerryLogo] = useState(ferryLogoTextColor);

  const displayRoutine = () => {
    if (state.isAuth) {
      if (routine === 'logout') {
        dispatch({ type: 'LOGOUT_USER' });
        return <Redirect to='/login' />
      }
      return <Redirect to="/" />;
    }

    return <Login />
  };

  return (
    <Skeleton
      id="AuthPage"
      noFooter
      noHeader
    >
	  <br/>
      <div className="h-100 d-flex flex-column justify-content-center align align-items-center">
        <Link to="/" className="brand-logo">
          <img
            src={ferryLogo}
            alt="FerryApp"
            className="w-100 mb-2"
            style={{transform: "scale(4)"}}
          />
        </Link>
        <br />
        
        {displayRoutine()}
        
      </div>
    </Skeleton>
  );
};

export default Auth;
