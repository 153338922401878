import React, { useState, useEffect } from 'react';
import logo from '../../../ferryassets/img/ferry_app_icon.png'
import poom from '../../../ferryassets/img/poom.png'
import { useCookies } from 'react-cookie';
import { makeRequest } from '../../../client';
import Doc from './DocService';
import PdfContainer from './PdfContainer';
import { GET_BOOKINGS, GET_RIDES } from '../../../graphql/queries'
import { withRouter } from 'react-router'
import { withClient } from '../../../client'

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#ffff',
	},
}))

const GenerateTicket = ({makeRequest, location : routerLocation}) => {
	console.log('routerLocation ', routerLocation);
	const [bookings, setBookings] = useState([]);
	const [featuredRides, setFeaturedRides] = useState([]);
	const [showRiders, setShowRiders] = useState(null);
	const [cookies, removeCookie] = useCookies();

	const [bookingId, setBookingId] = useState(routerLocation.state?.bookingId);
	const [rideName, setRideName] = useState(null);
	const [rideId, setRideId] = useState(routerLocation.state?.rideId);
	const [transactionId, setTransactionId] = useState(routerLocation.state?.transactionId);
	const [timestamp, setTimestamp] = useState(routerLocation.state?.timestamp);
	const [status, setStatus] = useState(routerLocation.state?.status);
	const [riders, setRiders] = useState(routerLocation.state?.riders || []);
	const [startTime, setStartTime] = useState();
	const [endTime, setEndTime] = useState("");
	const [rideType, setRideType] = useState("");
	const [date, setDate] = useState("");
	const [location, setLocation] = useState("");
	const [price, setPrice] = useState(0);
	const [totalPrice, setTotalPrice] = useState(0);
	const [netCharges, setNetCharges] = useState(0);
	const [basePrice, setBasePrice] = useState(0);

	const [tcount, setTcount] = useState(routerLocation.state?.tcount);
	const [ridePrice, setRidePrice] = useState(0);
	const classes=useStyles();

	const ref = React.createRef();

	const options = {
		orientation: 'landscape'
	};

	const YMDHMS = (datetime) => {
		const pad2 = (n) => n < 10 ? '0' + n : n 
		var date = new Date(datetime);
		return `${pad2(date.getDate())}/${pad2(date.getMonth()+1)}/${date.getFullYear()} ${pad2(date.getHours())}:${pad2(date.getMinutes())}:${pad2(date.getSeconds())}`
	}


	useEffect(()=>{
		makeRequest(GET_RIDES).then((res) => {
			for(var i=0;i<res.getRides.length;i++){
				if(res.getRides[i].id===rideId){
					setRideName(res.getRides[i].name);
					setStartTime(YMDHMS(res.getRides[i].startTime).substring(11, 16));
					setEndTime(YMDHMS(res.getRides[i].endTime).substring(11, 16));
					setDate(YMDHMS(res.getRides[i].startTime).substring(0, 10));
					setLocation(res.getRides[i].location);
					setPrice(parseInt(res.getRides[i].price));
					setRideType(res.getRides[i].rideType);
					
					setRidePrice(res.getRides[i].price);
					if(tcount != null){
						setTotalPrice((parseInt(res.getRides[i].price) * tcount) + (parseInt(res.getRides[i].price) * tcount * 0.025));
						setNetCharges((parseInt(res.getRides[i].price) * tcount * 0.025));
						setBasePrice((parseInt(res.getRides[i].price) * tcount));
					}
					else if(tcount == null){
						setTotalPrice((parseInt(res.getRides[i].price) * riders.length) + (parseInt(res.getRides[i].price) * riders.length * 0.025));
						setNetCharges((parseInt(res.getRides[i].price) * riders.length * 0.025));
						setBasePrice((parseInt(res.getRides[i].price) * riders.length));
					}
					
					//console.log('totalprice ', totalPrice);
					//console.log('price ', price);
					//console.log('tcount ', tcount);
					//console.log('netcharge ', netCharges);
				}
			}
		})
	}, [])

	const createPdf = (html) => Doc.createPdf(html);

	const riderInfo = () => {
		if(riders.length === 0)
			return null
		const data = riders;
		return (
				data.map((rider,ix) => {
					return (
						<div className="d-flex">
							<div>
								<h5 className="card-title"><b>{rider.fullName}</b> <br />Age & Gender:  <b>{rider.age} , {rider.gender}</b></h5>
							</div>
							<div className="ml-auto">
								<h5>Aadhar / Passport Number:  <b>{rider.aadhar}</b></h5>
							</div>
							<div className="ml-auto">
								<h5>Phone: {rider.phone===null ? (<b>N/A&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>) : ( <b>{rider.phone}</b>)}</h5>
							</div>
						</div>
					)
				})
		)
	}
	const riderCountFn = () => {
		return (
			<div className="ml-auto">
							<h6>Number of Persons:  <b>{tcount}</b></h6>
						</div>
		)
	}
	
	return (
		<div className="container bg-light mt-5 p-3 px-4 rounded-lg">
			<h1 className="mb-4"> Your Ticket </h1>
			<PdfContainer createPdf={createPdf}>
			<div className="card-group">
				<div className="card">
					<div className="card-body" ref={ref}>
						<div className="d-flex">
							<div>
								<h3 className="card-title"><b>E-Ticket</b></h3>
								<h5>Booking ID: <b>{bookingId}</b></h5>
								<h5>Booked on: <b>{YMDHMS(timestamp)}</b></h5>
								
							</div>
							<div className="ml-auto">
								<img src={logo} alt="logo" style={{height: 100, width: 100}}></img>
							</div>
						</div>
						<br />
						<br />
						<div className="d-flex">
							<div>
								<h5 className="card-title"> Ride Name: <b>{rideName}</b></h5>
								<h5> Location: <b>{location}</b></h5>
							</div>
							<div className="ml-auto">
								<h5>Ride Type: <b>{rideType}</b></h5>
								<h5>Ride Status: <b>{status}</b></h5>
							</div>
						</div>
						<hr style={{color: '#007bff', backgroundColor: '#007bff', height: .5, borderColor : '#007bff'}}></hr>
						<div className="d-flex">
							<div>
								<h5 className="card-title">Start Time: <b>{startTime}</b></h5>
								<h4 className="card-title"><b>{date}</b></h4>
							</div>
							<div className="ml-auto">
								<img src={poom} alt="logo" style={{height: 40}}></img>
							</div>
							<div className="ml-auto">
								<h5>End Time: <b>{endTime}</b></h5>
								<h4 className="card-title"><b>{date}</b></h4>
							</div>
						</div>
						<hr style={{color: '#6c757d', backgroundColor: '#6c757d', height: .3, borderColor : '#6c757d'}}></hr>
						<div className="d-flex justify-content-center align-items-center">
							<h2> Travellers Information </h2>
						</div>
						<div className="d-flex">
							<div>
								{/* <h5 className="card-title" style={{color: "#6c757d"}}> Traveller</h5> */}
							<h6 className="card-title"><b>{riderCountFn()}</b></h6>
						</div>
						<div className="ml-auto">
							<h6>Ticket Fare:  <b>{ridePrice}/-</b></h6>
						</div>
						<div className="ml-auto">
							<h6></h6>
						</div>
					</div>
							<div className="d-flex">
								<div>
									<h6 className="card-title" style={{ color: "#6c757d" }}> Traveller Details</h6>
							</div>
							<div className="ml-auto">
							</div>
						</div>
						{riderInfo()}
						
						<br />
						<br />
						<div className="d-flex">
							<div>
								<h5 className="card-title" style={{color: "#6c757d"}}> Fare and Payment Details</h5>
							</div>
							<div className="ml-auto">
							</div>
						</div>

						<hr style={{color: '#6c757d', backgroundColor: '#6c757d', height: .3, borderColor : '#6c757d'}}></hr>
						<div className="d-flex">
							<div>
								{/*<h6 className="card-title">Base Amount: <b>Rs. {price*riders.length}</b></h6>*/}
								<h6 className="card-title">Base Amount: <b>Rs. {basePrice}</b></h6>
								{/*<h6 className="card-title">Net Charges: <b>Rs. {price*riders.length*0.02}</b></h6>*/}
								<h6 className="card-title">Net Charges: <b>Rs. {netCharges}</b></h6>
								{/*<h6 className="card-title">Total Amount: <b>Rs. {price*riders.length+price*riders.length*0.02}</b></h6>*/}
								<h6 className="card-title">Total Fare: <b>Rs. {totalPrice? totalPrice : <Backdrop
        							className={classes.backdrop}
        							open>
        							<CircularProgress color="inherit" />
      								</Backdrop>}/- </b></h6>
								<h6 className="card-title">Transaction ID: <b>{transactionId}</b></h6>
							</div>
							<div className="ml-auto">
							</div>
						</div>
						<br />

						<div className="d-flex">
							<div>
								<h5 className="card-title" style={{color: "#6c757d"}}> Cancellation policy</h5>
							</div>
							<div className="ml-auto">
							</div>
						</div>

						<hr style={{color: '#6c757d', backgroundColor: '#6c757d', height: .3, borderColor : '#6c757d'}}></hr>
						<div className="d-flex">
							<div>
								<ul>
									<li>If cancelled the ticket, before 24 hrs of boarding time, 100% refund will be allowed, except admin and net charges.</li>
									<li>If cancelled before 12–24 hrs, 75% refund will be allowed, except admin and net charges.</li>
									<li>If cancelled before 4 – 8 hrs 50% refund will be allowed, except admin and net charges.</li>
									<li>If cancelled within 4 hrs No refund will be allowed.</li>
									<li>If service is cancelled due to bad weather or any other technical issues then the passenger was requested to avail the next other available slots for the same day or in the next day. If the passengers not agreed, full refund will be done in online.</li>
								</ul>
							</div>
							<div className="ml-auto">
							</div>
						</div>
						
						<br />
						<br />

						<div className="d-flex">
							<div>
								<h5 className="card-title" style={{color: "#6c757d"}}> Tourist Places</h5>
							</div>
							<div className="ml-auto">
							</div>
						</div>

						<hr style={{color: '#6c757d', backgroundColor: '#6c757d', height: .3, borderColor : '#6c757d'}}></hr>
						<div className="d-flex">
							<div>
							<p>Nearest Kanyakumari</p>	
								<ol>			
									<li>Vivekananda Rock Memorial</li>
									<li>AyyanThiruvalluvar</li>
									<li>Sunrise & Sunset view tower</li>
									<li>Devi Bagavathi Amman Temple</li>
									<li>Mahatma Gandhi Memorial</li>
									<li>kamarajar Memorial</li>
									<li>Triveni Sangamam</li>
									<li>Vattakottai Fort</li>
									<li>Thanumalayan Temple - Susindrum</li>
									<li>Nagaraja Temple - Nagarcoil</li>
									<li>Ramayana Mandapam - Vivekanandapuram</li>
									<li>Our Lady of Ransom Church - Kanyakumari</li>
								</ol>
							</div>
							<div className="ml-auto">
							</div>
						</div>
						<div className="d-flex">
							<div>
								<h4>Conditions</h4>	
								<ol>			
									<li>The ticket is Not Transferable.</li>
									<li>Please kindly note that special entry is only for upward journey and not for returm, since it is not necessary. Kindly cooperate zan addressed by crew  </li>
									<li>Passengers are requested to adhere to safety rules.- Safety First. </li>
									<li>Kindly keep the ticket/id readily available for verification.  </li>
									<li>The entry will be easy if you reach 2 hours before the time of boarding. Please kindly avoid last minute rush.  </li>
									<li>The original Aadhar id card to be shown at the time of entry for Indians, forforeigners original passport id to shown by the visitor.  </li>
									<li>If service is cancelled due to bad weather or any other technical issues then the passenger s requested to avail the next ether available slots for the same day or in the next day. If requested, full refund will be done in online. </li>
									<li>Ferry service is subject to weather/wave/wind/tide conditions. </li>
								</ol>
							</div>
							<div className="ml-auto">
							</div>
						</div>
					</div>
				</div>
			</div>
			</PdfContainer>
		</div>
	)
}

export default withClient(withRouter(GenerateTicket));