import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { withClient } from '../../../client';
import logoFull from '../../../ferryassets/img/poom.png';
import { GET_BOOKING_DATA } from '../../../graphql/queries';
import { getDates } from '../../../utils/generalUtil';
import GetExcelFile from './GetExcelFile';

const Rides = ({ makeRequest }) => {

  const [download, setDownload] = useState(false)
  const [exportData, setExportData] = useState([])
  const [searchDate, setSearchDate] = useState(["", ""])
  useEffect(() => {
    if (download) {
      setDownload(false)
    }
  }, [download])


  const Details = () => {
    const card = exportData ? exportData.map((data, i) => {
      return (
        <tr key={"date" + i}>
          <td>{i}</td>
          <td>{data.date}</td>
          <td>{data.passengers}</td>
          <td>{data.normalTicket}</td>
          <td>{data.normalAmount}</td>
          <td>{data.specialTicket}</td>
          <td>{data.specialAmount}</td>
          <td>{data.schoolTicket}</td>
          <td>{data.schoolAmount}</td>
          <td>{data.ticketAmount}</td>
          <td>{data.amtExcTax}</td>
          <td>{data.igst}</td>
          <td>{data.sgst}</td>
          <td>{data.tnmb}</td>
          <td>{data.ttdc}</td>
          <td>{data.psc}</td>
        </tr>
      );
    }) : []
    return card;
  };
  return (
    <div>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title as="h5">Booking Data Management</Card.Title>
              <span className="d-block m-t-5"> Export data for the date range.</span>
              <br />

            </Card.Header>
            <Form onSubmit={(e) => {
              e.preventDefault()
            }}>
              {/* <h3 className="ml-3"></h3> */}
              <div className="mt-2 clearfix">
                <div className="row">
                  <div className="col-4">

                    <div id="booker-picker1" className="input-group mx-2">
                      <input type="date" className="form-control"
                        onChange={(e) => {
                          e.persist();
                          setSearchDate(sd => {
                          if(e?.target?.value){
                            sd[0] = e?.target?.value
                            console.log(e?.target?.value, sd)
                          }
                          return sd
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div id="booker-picker2" className="input-group mx-2">
                      <input type="date" className="form-control"
                        onChange={(e) => {
                          e.persist();
                          setSearchDate(sd => {
                            if(e.target.value){
                              sd[1] = e?.target?.value
                              console.log(e?.target?.value, sd)
                            }
                            return sd
                          })
                        }}
                      />
                    </div>
                  </div>

                </div>
              </div>
              <br />
              <Button variant="primary" type="submit"
                className="ml-2 mt-3"
                onClick={async e => {
                  if (searchDate[0] && searchDate[1]) {
                    let vars = {}
                    vars.byDateRange = searchDate
                    const { getBookingData: bookings } = await makeRequest(GET_BOOKING_DATA, vars)
                    var grouped = bookings.reduce(function (r, a) {
                      let date = new Date(a.timestamp)
                      date = ((date.getDate() < 10) ? "0" : "") + date.getDate() + "-" + ((date.getMonth() < 9) ? "0" : "") + (date.getMonth() + 1) + "-" + date.getFullYear()
                      r[date] = r[date] || [];
                      r[date].push(a);
                      return r;
                    }, {});

                    grouped = getDates((new Date(searchDate[0])), (new Date(searchDate[1]))).map(v => {
                      let valueDate = ((v.getDate() < 10) ? "0" : "") + v.getDate() + "-" + ((v.getMonth() < 9) ? "0" : "") + (v.getMonth() + 1) + "-" + v.getFullYear()
                      let passengers = 0
                      let normalTicket = 0
                      let specialTicket = 0
                      let schoolTicket = 0
                      let normalAmount = 0
                      let specialAmount = 0
                      let schoolAmount = 0
                      let ticketAmount = 0
                      let igst = 0
                      let sgst = 0
                      let tnmb = 0
                      let ttdc = 0
                      if (grouped[valueDate]) {
                        grouped[valueDate].map(gd => {
                          let priceTotal = gd.price * gd.riders
                          passengers += gd.riders
                          ticketAmount += parseInt(priceTotal)
                          igst += parseInt(gd.igst * gd.riders)
                          sgst += parseInt(gd.sgst * gd.riders)
                          tnmb += parseInt(gd.tnmb * gd.riders)
                          ttdc += parseInt(gd.ttdc * gd.riders)
                          normalAmount += ((gd.rideType == "General") ? parseInt(priceTotal) : 0)
                          specialAmount += ((gd.rideType == "Special") ? parseInt(priceTotal) : 0)
                          schoolAmount += ((gd.rideType == "School") ? parseInt(priceTotal) : 0)
                          normalTicket += ((gd.rideType == "General") ? gd.riders : 0)
                          specialTicket += ((gd.rideType == "Special") ? gd.riders : 0)
                          schoolTicket += ((gd.rideType == "School") ? gd.riders : 0)
                        })
                        let amtExcTax = ticketAmount - igst - sgst
                        let psc = ticketAmount - igst - sgst - tnmb - ttdc
                        return { date: valueDate, passengers, normalTicket, specialTicket, ticketAmount, normalAmount, specialAmount, schoolTicket, schoolAmount, igst, sgst, tnmb, ttdc, amtExcTax, psc }
                      } else {
                        return { date: valueDate, passengers: 0, normalTicket: 0, specialTicket: 0, ticketAmount: 0, normalAmount: 0, specialAmount: 0, schoolTicket: 0, schoolAmount: 0, igst: 0, sgst: 0, tnmb: 0, ttdc: 0, amtExcTax: 0, psc: 0 }
                      }
                    })
                    setExportData(grouped)
                  } else {
                    Swal.fire({
                      imageUrl: logoFull,
                      imageWidth: "150px",
                      title: 'Error',
                      text: 'Please enter date range.',
                      confirmButtonText: 'OK',
                      heightAuto: false
                    });
                  }
                }}>
                Find
        </Button>
        {exportData.length?<Button variant="primary" type="submit"
                className="ml-2 mt-3"
                style={{float:"right"}}
                onClick={e=>setDownload(true)}
              >Export</Button>:""}
              {download ? <GetExcelFile hideElement={true} exportData={exportData} name="Bookings" />:""}
            </Form>

            {exportData && exportData.length ? <Table responsive hover id="table-to-xls">
              <thead>
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>No. Passengers</th>
                  <th>Normal Ticket</th>
                  <th>Amount</th>
                  <th>Special Ticket</th>
                  <th>Amount</th>
                  <th>Student</th>
                  <th>Amount</th>
                  <th>Ticket Amount</th>
                  <th>Amt exc GST</th>
                  <th>IGST 9%</th>
                  <th>SGST 9%</th>
                  <th>TNMB 5%</th>
                  <th>TTDC Rs5/ticket</th>
                  <th>PSC</th>
                </tr>
              </thead>
              <tbody>
                {<Details />}
              </tbody>
            </Table> : ""}
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default withClient(Rides);