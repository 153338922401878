import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {useSelector} from 'react-redux';

import RouteWithErrorBoundary from './ErrorBoundary'

const ProtectedRoute = ({ component: Component, ...rest }) => {
	const state = useSelector((state) => state.auth)
	const [cookies] = useCookies();

	if(state.isAuth && !cookies.token){
		return(
			<Redirect to="/logout" />
		)
	}

	return (
	<RouteWithErrorBoundary
	  render={props =>
	    !state.isAuth ? <Redirect to="/login" /> : <Component {...props} />
	  }
	  {...rest}
	/>
	);
};

export default ProtectedRoute;
