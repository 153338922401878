export const GET_USERS_CREATED = `
	query($byCreatedAt: Date){
		getUsersCreated(byCreatedAt: $byCreatedAt){
			date
			count
		}
	}
`

export const GET_BOOKINGS_CREATED = `
	query($byCreatedAt: Date){
		getBookingsCreated(byCreatedAt: $byCreatedAt){
			date
			count
		}
	}
`

export const GET_BOOKING_PAGES = `
	query(
		$byBookingId: [ID]
		$byRideId: [ID]
		$byBookerId: [ID]
		$byTransactionId: [String]
		$byStatus: Status
		$byTimestamp: Date
		$byTimestampAfter: Date
	){
		getBookingPages(
			byBookingId: $byBookingId,
			byRideId: $byRideId,
			byBookerId: $byBookerId,
			byTransactionId: $byTransactionId,
			byStatus: $byStatus,
			byTimestamp: $byTimestamp,
			byTimestampAfter: $byTimestampAfter
		)
	}
`

export const GET_BULK_BOOKING_PAGES = `
	query(
		$byBookingId: [ID]
		$byRideId: [ID]
		$byBookerId: [ID]
		$byTransactionId: [String]
		$byStatus: Status
		$byTimestamp: Date
		$byTimestampAfter: Date
	){
		getBulkBookingPages(
			byBookingId: $byBookingId,
			byRideId: $byRideId,
			byBookerId: $byBookerId,
			byTransactionId: $byTransactionId,
			byStatus: $byStatus,
			byTimestamp: $byTimestamp,
			byTimestampAfter: $byTimestampAfter
		)
	}
`

export const GET_BOOKINGS_PER_RIDE = `
	query{
		getBookingsPerRide{
			ride
			count
		}
	}
`

export const GET_BULK_BOOKINGS_PER_RIDE = `
	query{
		getBulkBookingsPerRide{
			ride
			count
		}
	}
`

export const GET_RIDES = `
	query($byStartTimeGreaterEqual:Date, $byEndTimeLesserEqual: Date){
		getRides(withBooked: true, byStartTimeGreaterEqual: $byStartTimeGreaterEqual, byEndTimeLesserEqual: $byEndTimeLesserEqual){
			id
			name
			description
			price
			location
			startTime
			endTime
			capacity
			status
			totalBooked
			rideType
		}
	}
`

export const GET_RIDES_DATE = `
	query($byStartTimeEqual:Date){
		getRides(withBooked: true, byStartTimeEqual: $byStartTimeEqual){
			id
			name
			description
			price
			location
			startTime
			endTime
			capacity
			status
			totalBooked
			rideType
		}
	}
`

export const GET_BOOKINGS = `
	query(
		$byBookingId: [ID]
		$byRideId: [ID]
		$byBookerId: [ID]
		$byTransactionId: [String]
		$byStatus: Status
		$byTimestamp: Date
		$byTimestampAfter: Date
		$byDateRange: [Date]
		$page: Int
	){
		getBookings(
			byBookingId: $byBookingId,
			byRideId: $byRideId,
			byBookerId: $byBookerId,
			byTransactionId: $byTransactionId,
			byStatus: $byStatus,
			byTimestamp: $byTimestamp,
			byTimestampAfter: $byTimestampAfter
			byDateRange:$byDateRange
			page: $page
		){
			id
			rideId
			bookerId
			riders{
				fullName
				age
				phone
				aadhar
				gender
			}
			transactionId
			timestamp
			status
			tcount
		}
	}
`

export const GET_BOOKING_DATA = `
	query(
		$byDateRange: [Date]
	){
		getBookingData(
			byDateRange:$byDateRange
		){
			timestamp
			riders
			price
			actualPrice
			igst
			sgst
			tnmb
			ttdc
			rideType
		}
	}
`

export const GET_BULK_BOOKINGS = `
	query(
		$byBookingId: [ID]
		$byRideId: [ID]
		$byBookerId: [ID]
		$byTransactionId: [String]
		$byStatus: Status
		$byTimestamp: Date
		$byTimestampAfter: Date
		$page: Int
	){
		getBulkBookings(
			byBookingId: $byBookingId,
			byRideId: $byRideId,
			byBookerId: $byBookerId,
			byTransactionId: $byTransactionId,
			byStatus: $byStatus,
			byTimestamp: $byTimestamp,
			byTimestampAfter: $byTimestampAfter
			page: $page
		){
			id
			rideId
			bookerId
			bookerName
			riderCount
			schoolName
			schoolDistrict
			aadhar
			transactionId
			timestamp
			status
			fileUrl
		}
	}
`

export const GET_BOOKINGS_PDF = `
	query {
		getBookingsPdf{
			id
			rideName
			transactionId
			timestamp
			status
			riders{
				fullName
				age
				phone
				aadhar
				gender
			}
		}
	}
`

export const GET_BOOKING_EXCEL = `
	query(
		$rideId: ID!,
		$timestampAfter: Date,
		$timestampBefore: Date
	){
		getBookingsExcel(
			rideId: $rideId,
			timestampBefore: $timestampBefore,
			timestampAfter: $timestampAfter
		){
			id
			rideId
			bookerId
			transactionId
			timestamp
			status
			riders{
				fullName
				age
				phone
				aadhar
				gender
			}
		}
	}
`

export const GET_BOOKINGS_BY_RIDE = `
query($rideId:ID!){
	getBookingsByRide(rideId: $rideId)
}
`

export const GET_BOOKINGS_BY_RIDES = `
query($byRideId: ID){
	getBookingsByRide(byRideId: $byRideId)
}
`

export const SEARCH_BOOKINGS = `
  query(
    $rideId: [ID]
  ){
    getBookingsByRide(
      rideId: $rideId
    )
  }
`

export const SEARCH_RIDES = `
  query(
    $byRideId: [ID],
    $byName: [String],
    $byPrice: [Int],
    $byPriceLesser: Int,
    $byPriceGreater: Int,
    $byPriceLesserEqual: Int,
    $byPriceGreaterEqual: Int,
    $byLocation: [String],
    $byStartTime: [Date],
    $byStartTimeLesser: Date,
    $byStartTimeLesserEqual: Date,
    $byStartTimeGreater: Date,
    $byStartTimeGreaterEqual: Date,
    $byCapacity: [Int],
    $byCapacityLesser: Int,
    $byCapacityGreater: Int,
    $byCapacityLesserEqual: Int,
    $byCapacityGreaterEqual: Int,
    $byRideType: [String],
    $withBooked: Boolean
  ){
    getRides(
      byRideId: $byRideId,
      byName: $byName,
      byPrice: $byPrice,
      byPriceLesser: $byPriceLesser,
      byPriceGreater: $byPriceGreater,
      byPriceLesserEqual: $byPriceLesserEqual,
      byPriceGreaterEqual: $byPriceGreaterEqual,
      byLocation: $byLocation,
      byStartTime: $byStartTime,
      byStartTimeLesser: $byStartTimeLesser,
      byStartTimeLesserEqual: $byStartTimeLesserEqual,
      byStartTimeGreater: $byStartTimeGreater,
      byStartTimeGreaterEqual: $byStartTimeGreaterEqual,
      byCapacity: $byCapacity,
      byCapacityLesser: $byCapacityLesser,
      byCapacityGreater: $byCapacityGreater,
      byCapacityLesserEqual: $byCapacityLesserEqual,
      byCapacityGreaterEqual: $byCapacityGreaterEqual,
      byRideType: $byRideType,
      withBooked: $withBooked,
      byStatus: active
    ){
      id
      name
      description
      price
      actualPrice
      sgst
      igst
      ttdc
      tnmb
      location
      startTime
      endTime
      capacity
      status
      rideType
      totalBooked
    }
  }
`