import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { withRouter, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useCookies } from 'react-cookie';

import config from '../../../config.js';
import logoFull from '../../../ferryassets/img/poom.png';
import { postRequest } from '../../../utils/requests';
import {connect} from 'react-redux';
import axios from 'axios';

import 'bootstrap/dist/js/bootstrap';
import '../index.scss';

const Login = ({ loginUser, history }) => {
  const [status, setStatus] = useState('primary');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [cookies,,removeCookie] = useCookies();

  const [red,setRed] = useState(false)

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      setSubmitting(true);

      const user = { email: email, password: password };
      const userJSON = JSON.stringify(user);
      
      // Login URL for Auth
      const url = `${process.env.REACT_APP_BACKEND_URL || config.backendUrl}/auth/login`;
      
      const response = await postRequest(url,user).catch((err)=>{
        Swal.fire({
          imageUrl:logoFull,
            imageWidth: "150px",
            title: 'Error',
            text: 'Incorrect username or password. Please try again.',
            confirmButtonText: 'OK',
            heightAuto: false
        });
        
        setStatus('danger');
        setSubmitting(false);
        return 
      })

      if(response.status === 200)
      {
        const user = response.data.user;
        setSubmitting(false);
        if(!user.isAdmin){
          removeCookie('token', null, { maxAge: 0 });
          Swal.fire({
            imageUrl:logoFull,
              imageWidth: "150px",
              title: 'Error',
              text: 'Incorrect Username or Password, Please try again.',
              confirmButtonText: 'OK',
              heightAuto: false
          });

          setStatus('danger');
          setSubmitting(false);
          return
        }
        await loginUser(user)
        // history.push({
        //   pathname: '/dashboard'
        // });
        setRed(true)
      }

  } catch (err) {
      Swal.fire({
        imageUrl:logoFull,
          imageWidth: "150px",
          title: 'Error',
          text: 'Incorrect Username or Password, Please try again.',
          confirmButtonText: 'OK',
          heightAuto: false
      });

      setStatus('danger');
      setSubmitting(false);
      console.error({ err });
    }
  };

  if(red)
    return (<Redirect to="/dashboard" />)

  return (
    <div id="Routine" className="mb-5 card shadow-lg p-3 mb-5 bg-white box" >
          <center>
          <h1>Admin Login</h1>
          </center>
          <div className={classNames('card border-top', `border-${status}`)}>
            <div className="card-body py-4">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>
                    <i className="fas fa-at" />
                    &nbsp; Email
                    <input
                      type="text"
                      placeholder="Enter your Email Address"
                      className={classNames('form-control mt-2', {
                        'is-valid': status === 'success',
                        'is-invalid': status === 'danger'
                      })}
                      style={{padding:15,borderRadius:10}}
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                      disabled={submitting}
                      required
                    />
                  </label>
                  <small className="form-text text-muted">
                    We'll never share your email with anyone else.
                  </small>
                </div>
                
                  <div className="form-group">
                    <label>
                      <i className="fas fa-unlock-alt" />
                      &nbsp; Password
                      <input
                        type="password"
                        placeholder="Enter your Password"
                        className={classNames('form-control mt-2', {
                          'is-valid': status === 'success',
                          'is-invalid': status === 'danger'
                        })}
                        style={{padding:15,borderRadius:10}}
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                        disabled={submitting}
                        required
                      />
                    </label>
                    <small className="form-text text-muted">
                      Don't share your password with anyone else!
                    </small>
                  </div>
              
                <button
                  type="submit"
                  disabled={submitting}
                  className={classNames('btn btn-block', `btn-${status}`)}
                  style={{padding:10,borderRadius:10}}
                >
                  {submitting ? (
                    <span>
                      <i className="fas fa-circle-notch fa-spin" />
                      &nbsp; Loading
                    </span>
                  ) : ('Login'
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
  );
};

const mapDispatchToProps = dispatch => {
    return {
        loginUser: (user) => dispatch({type: 'LOGIN_USER', payload: user}),
    }
};

export default connect(null,mapDispatchToProps)(withRouter(Login));
