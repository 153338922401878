import React, {useState,useEffect,useRef} from 'react';
import { withClient } from '../../../client'
import { GET_USERS_CREATED, GET_BOOKINGS_CREATED, GET_BOOKINGS_PER_RIDE, GET_BULK_BOOKINGS_PER_RIDE } from '../../../graphql/queries'
import { Bar, Doughnut } from 'react-chartjs-2';

const Home = ({makeRequest}) => {

	const [users,setUsers] = useState([])
	const [bookings,setBookings] = useState([])
	const [perRide,setPerRide] = useState([])
	const [bulkPerRide,setBulkPerRide] = useState([])
	const [fetchingUser,setFetchingUser] = useState(true)
	const [fetchingBooking,setFetchingBooking] = useState(true)
	const [fetchingPerRide,setFetchingPerRide] = useState(true)
	const [fetchingBulkPerRide,setFetchingBulkPerRide] = useState(true)

	const colorGen = (numOfSteps, step) => {
	    var r, g, b;
	    var h = step / numOfSteps;
	    var i = ~~(h * 6);
	    var f = h * 6 - i;
	    var q = 1 - f;
	    switch(i % 6){
	        case 0: r = 1; g = f; b = 0; break;
	        case 1: r = q; g = 1; b = 0; break;
	        case 2: r = 0; g = 1; b = f; break;
	        case 3: r = 0; g = q; b = 1; break;
	        case 4: r = f; g = 0; b = 1; break;
	        case 5: r = 1; g = 0; b = q; break;
	    }
	    var c = "#" + ("00" + (~ ~(r * 255)).toString(16)).slice(-2) + ("00" + (~ ~(g * 255)).toString(16)).slice(-2) + ("00" + (~ ~(b * 255)).toString(16)).slice(-2);
	    return (c+99);
	}

	useEffect(() => {
		const vars = {
			byCreatedAt: new Date()
		}
		makeRequest(GET_USERS_CREATED, vars).then((res) => {
			let data = {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [],
						label: 'Users'
					}
				]
			}
			res.getUsersCreated.forEach((day,ix) => {
				data.labels.push(day.date.slice(0,10).toString())
				data.datasets[0].data.push(day.count)
				data.datasets[0].backgroundColor.push('#ff000099')
			})
			setUsers(data)
			setFetchingUser(false)
		}).catch(()=>{})
	}, [])

	useEffect(() => {
		const vars = {
			byCreatedAt: new Date()
		}
		makeRequest(GET_BOOKINGS_CREATED, vars).then((res) => {
			let data = {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [],
						label: 'Bookings'
					}
				]
			}
			res.getBookingsCreated.forEach((day,ix) => {
				data.labels.push(day.date.slice(0,10).toString())
				data.datasets[0].data.push(day.count)
				data.datasets[0].backgroundColor.push('#00aaff99')
			})
			setBookings(data)
			setFetchingBooking(false)
		}).catch(()=>{})
	}, [])

	useEffect(() => {
		makeRequest(GET_BOOKINGS_PER_RIDE).then((res) => {
			let data = {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [],
						label: 'Rides with most bookings'
					}
				]
			}
			res.getBookingsPerRide.forEach((ride,ix) => {
				data.labels.push(ride.ride)
				data.datasets[0].data.push(ride.count)
				data.datasets[0].backgroundColor.push(colorGen(res.getBookingsPerRide.length,ix))
			})
			setPerRide(data)
			setFetchingPerRide(false)
		}).catch(()=>{})
	}, [])

	useEffect(() => {
		makeRequest(GET_BULK_BOOKINGS_PER_RIDE).then((res) => {
			let data = {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: [],
						label: 'Bulk Rides with most bookings'
					}
				]
			}
			res.getBulkBookingsPerRide.forEach((ride,ix) => {
				data.labels.push(ride.ride)
				data.datasets[0].data.push(ride.count)
				data.datasets[0].backgroundColor.push(colorGen(res.getBulkBookingsPerRide.length,ix))
			})
			setBulkPerRide(data)
			setFetchingBulkPerRide(false)
		}).catch(()=>{})
	}, [])

	return (
		<div className="container p-3">
			<div className="row mb-2">
				{/*
				<div className="col-6 container bg-white rounded p-4">
					<h3 className="text-center mb-3">New users over the last 10 days</h3>
					{fetchingUser ? (
						<div class="d-flex justify-content-center">
						  <div class="spinner-border" role="status">
						  </div>
						</div>
					) : (
						<Bar 
						  data={users}
						/>
					)}
				</div>
					*/}
				<div className="col-6 container bg-white rounded p-4">
					<h3 className="text-center mb-3">Tickets booked over the last 30 days</h3>
					{fetchingBooking ? (
						<div class="d-flex justify-content-center">
						  <div class="spinner-border" role="status">
						  </div>
						</div>
					) : (
						<Bar 
						  data={bookings}
						/>
					)}
				</div>
			</div>
			{/*
			<div className="row justify-content-center">
				<div className="col-6 container bg-white rounded p-4">
					<h3 className="text-center mb-3">Rides with most bookings</h3>
					{fetchingPerRide ? (
						<div class="d-flex justify-content-center">
						  <div class="spinner-border" role="status">
						  </div>
						</div>
					) : (
						<Doughnut 
						  data={perRide}
						/>
					)}
				</div>
				<div className="col-6 container bg-white rounded p-4">
					<h3 className="text-center mb-3">Rides with most bookings</h3>
					{fetchingBulkPerRide ? (
						<div class="d-flex justify-content-center">
						  <div class="spinner-border" role="status">
						  </div>
						</div>
					) : (
						<Doughnut 
						  data={bulkPerRide}
						/>
					)}
				</div>
			</div>
			*/}
		</div>
	)
}

export default withClient(Home);