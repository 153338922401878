import React from 'react';

export default (props) => {
  const bodyRef = React.createRef();
  const createPdf = () => props.createPdf(bodyRef.current);
  return (
    <section className="pdf-container">
      <section className="pdf-toolbar">
      <button 
        type="button" 
        className='btn btn-block btn-primary'
        style={{padding:10,borderRadius:10,width:200}}
        onClick={createPdf}>
          {props.btnLabel || "Download Ticket"} 
      </button>
      <br />
      </section>
      <section className="pdf-body" ref={bodyRef}>
        {props.children}
      </section>
    </section>
  )
}