import React, { useState,useEffect } from 'react';
import {Row, Col, Card, Table, Form, Button, InputGroup, FormControl, Modal} from 'react-bootstrap';
import DateTimePicker from "react-datetime-picker";
import classNames from 'classnames';

import Swal from 'sweetalert2';
import { withClient } from '../../../client';

import { ContactSupportOutlined } from '@material-ui/icons';
import { CANCEL_BOOKING } from '../../../graphql/mutation'
import { GET_BOOKINGS, GET_BOOKING_PAGES } from '../../../graphql/queries'

// import GenerateTicket from './GenerateTicket'
import { Link } from 'react-router-dom'

const Bookings = ({makeRequest}) =>  {

    const YMDHMS = (datetime) => {
      const pad2 = (n) => n < 10 ? '0' + n : n 
      var date = new Date(datetime);
      return `${pad2(date.getDate())}/${pad2(date.getMonth()+1)}/${date.getFullYear()} ${pad2(date.getHours())}:${pad2(date.getMinutes())}:${pad2(date.getSeconds())}`
    }

    const toLocale = (datetime) => {
        const locale = new Date(datetime).toLocaleString(undefined, {timezone: 'Asia/Kolkata'})
        return locale
    }

    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [showView, setShowView] = useState(false);
    const [showViewAdd, setShowViewAdd] = useState(false);
    const [bookingsIdx, setBookingsIdx] = useState('');
    const [bookingsSelected,setBookingsSelected] = useState({})
    const [bookingAlert, setBookingAlert] = useState(null);
    const [searchId, setSearchId] = useState('')
    const [searchDate, setSearchDate] = useState(null)
    const [searchType, setSearchType] = useState(null)

    const [pageNo, setPageNo] = useState(1);
    const [totalPages, setTotalPages] = useState(1)

    useEffect(() => { 
        handleSearch(searchType, true)
    }, [pageNo])

    useEffect(() => {
        setLoading(true)
        makeRequest(GET_BOOKING_PAGES).then((resp)=>{
            setTotalPages(resp.getBookingPages)
            makeRequest(GET_BOOKINGS).then((resp)=>{
                if(resp.getBookings)
                    setBookings(resp.getBookings)
                setLoading(false)
            }).catch(() => {setLoading(false)});
        }).catch(() => {setLoading(false)});
    }, [])

    const handleSearch = async (type,page) => {

        let vars = {}
        switch(type){
            case 'book':
                vars.byBookingId = [searchId]
                break
            case 'ride':
                vars.byRideId = [searchId]
                break
            case 'trans':
                vars.byTransactionId = [searchId]
                break
            case 'afterdate':
                vars.byTimestampAfter = searchDate
                break
        }
        setLoading(true)
        setSearchType(type)

        if(!page){
            const {getBookingPages: bookingPages} = await makeRequest(GET_BOOKING_PAGES,vars)
            setTotalPages(bookingPages)
            const {getBookings: bookings} = await makeRequest(GET_BOOKINGS,vars)
            setBookings(bookings)
            setPageNo(1)
        }

        if(page){
            vars.page = pageNo - 1
            const {getBookings: bookings} = await makeRequest(GET_BOOKINGS,vars)
            setBookings(bookings)
        }

        setLoading(false)
        // setSearchId('')
        // setSearchDate(null)
    }

    const handlePagination = (type) => {
        if(type === "up"){
            if(pageNo + 1 <= totalPages){
                setPageNo(pageNo + 1)
                // handleSearch(searchType)
            }
        } else {
            if(pageNo - 1 >= 1){
                setPageNo(pageNo - 1)
                // handleSearch(searchType)
            }
        }
    }

    const handleSelect = (id) => {
        if(!bookingsSelected[id])
            setBookingsSelected({...bookingsSelected,[id]: true})
        else {
            const newBookingsSelected = Object.assign({},bookingsSelected)
            delete newBookingsSelected[id]
            setBookingsSelected(newBookingsSelected);
        }
    }

    const cancelBookings = async () => {
        const bookingIds = Object.keys(bookingsSelected);
        if(bookingIds.length === 0)
            return
        const cancel = window.confirm("Do you really want to cancel ?")
        if(!cancel)
            return
        try{
            const res = await makeRequest(CANCEL_BOOKING,{bookingIds: bookingIds});
            setBookingsSelected({})
            await makeRequest(GET_BOOKINGS).then((resp)=>{
                setBookings(resp.getBookings)
            });
            setBookingAlert("Cancelled bookings")
        } catch (err){
            setBookingAlert("Could not cancel bookings")
        }
    }
    
    const Details = () => {
        const card = bookings ? bookings.map((booking,ix) => {
            return (
                <tr key={booking.id}>
                    <td>
                        <input 
                          className="form-control" 
                          type="checkbox"
                          onChange={()=>{
                            handleSelect(booking.id)
                          }}
                          checked={bookingsSelected[booking.id] ? true : false}
                        />
                    </td>
                    <td>
                    {' '}
                    {booking.id}
                    </td>
                    <td>
                    {' '}
                    {booking.rideId}
                    </td>
                    <td>
                    {' '}
                    {booking.transactionId}
                    </td>
                    <td>
                    {' '}
                    {/*{booking.riders.length}*/}
                    {booking.tcount}
                    </td>
                    <td>
                    {' '}
                    {YMDHMS(booking.timestamp)}
                    </td>
                    <td>
                    {' '}
                    {booking.status}{' '}
                    </td>
                    <td className="px-md-2 numeric text-center">
                        {' '}
                        { booking.status === "active" && (
                            <div className="btn btn-primary">
                                <Link
                                    to={{
                                    pathname: "/ticket",
                                    state: {
                                        bookingId: booking.id,
                                        rideId: booking.rideId,
                                        transactionId: booking.transactionId,
                                        riders: booking.riders,
                                        timestamp: booking.timestamp,
                                        status: booking.status,
                                        tcount: booking.tcount,
                                    }
                                    }}
                                    className="text-light"
                                >
                                View Ticket
                                </Link>
                            </div>
                        )}
                    </td>
                </tr>
            );
        }) : []
        return card;
    };

    return (
        <div>
            <Row>
                <Col>
                    <Card>
                        <Card.Header> 
                            <Card.Title as="h5">Bookings Management</Card.Title>
                            <span className="d-block m-t-5">Easily manage bookings here.</span>
                            <br />
                            <div className="clearfix">
                                <Button
                                  className="float-left btn btn-primary"
                                  onClick={()=>{
                                    cancelBookings()
                                  }}
                                > Cancel Booking </Button>
                                <div className="btn-group float-right ml-2" role="group">
                                  <button 
                                    type="button" 
                                    className="btn btn-secondary mr-1"
                                    onClick={() => {
                                        handleSearch('book')
                                    }}
                                  >By Booking Id</button>
                                  <button 
                                    type="button" 
                                    className="btn btn-secondary mr-1"
                                    onClick={() => {
                                        handleSearch('ride')
                                    }}
                                  >By Ride Id</button>
                                  <button 
                                    type="button" 
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        handleSearch('trans')
                                    }}
                                  >By Transaction Id</button>
                                </div>
                                <div className="input-group float-right w-25">
                                  <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Enter Id"
                                    onChange={(e) => {
                                        setSearchId(e.target.value)
                                    }}
                                    value={searchId}
                                  />
                                </div>
                            </div>
                            <div className="mt-2 clearfix">
                                <div className="btn-group float-right ml-2 pt-1" role="group">
                                  <button 
                                    type="button" 
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        handleSearch('afterdate')
                                    }}
                                  >After date</button>
                                </div>
                                <div id="booker-picker" className="input-group float-right w-25">
                                  <DateTimePicker 
                                    className="form-control" 
                                    onChange={(e) => {
                                        setSearchDate(e)
                                    }}
                                    value={searchDate}
                                  />
                                </div>
                            </div>
                            <h4 className="float-right">{bookingAlert}</h4>

                        </Card.Header>
                        <Card.Body  id="bookings">

                            <Table responsive hover id="table-to-xls">
                                <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>ID</th>
                                    <th>Ride Id</th>
                                    <th>Transaction ID</th>
                                    <th>Number Of Riders</th>
                                    <th>Booking Timestamp</th>
                                    <th>Status</th>
                                    <th>Ticket</th>
                                </tr>
                                </thead>
                                <tbody>
                                { !loading && <Details />}
                                </tbody>
                            </Table>
                            { loading && (
                                <div className="d-flex justify-content-center">
                                  <div className="spinner-border" role="status">
                                    <span></span>
                                  </div>
                                </div>
                            )}
                            <br />
                            <nav>
                              <ul className="pagination justify-content-center">
                                <li className={`page-item${pageNo === 1 ? " disabled" : null}`}
                                  onClick={
                                    () => {
                                        handlePagination('down')
                                    }
                                  }
                                >
                                    <a className="page-link">Previous</a>
                                </li>
                                <li className={`page-item${pageNo === totalPages ? " disabled" : null}`}
                                  onClick={
                                    () => {
                                        handlePagination('up')
                                    }
                                  }
                                >
                                    <a className="page-link">Next</a>
                                </li>
                              </ul>
                            </nav>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default withClient(Bookings);